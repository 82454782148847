import axios from 'axios'
const actions = {
  login({ commit }, credentials) {
    return axios.post('http://127.0.0.1:8001/internal/login', credentials).then(({ data }) => {
      commit('SET_USER_DATA', data)
    })
  },
}

export default { actions }
