<template>
  <CRow>
    <CCol sm="auto" v-if="params.totalCount > 0">
      <CPagination aria-label="Page navigation" size="sm">
        <CPaginationItem href="#" :disabled="hasPrev() == false" @click="() => onClickSegmentMove(-1)"
          ><span>&laquo;</span></CPaginationItem
        >
        <CPaginationItem
          href="#"
          :active="params.currentPage == page"
          v-for="page in getPages()"
          :key="page"
          @click="onChangePage(page)"
          >{{ page }}</CPaginationItem
        >
        <CPaginationItem href="#" :disabled="hasNext() == false" @click="() => onClickSegmentMove(1)"
          ><span>&raquo;</span></CPaginationItem
        >
      </CPagination>
    </CCol>
    <CCol sm="auto">
      <span class="mx-3 v-middle" v-if="getTotalPage() > 0">총: {{ getTotalPage() }}페이지</span>
    </CCol>
  </CRow>
</template>

<script>
import { BRAND_LIST } from '@/constants.js'

const MAX_PAGES_PER_SEGMENT = 20

export default {
  name: 'PaginationPane',
  props: {
    params: {
      type: Object,
      required: true,
    },
    onLoadPage: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      BRAND_LIST,
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    getTotalPage() {
      return Math.ceil(this.params.totalCount / this.params.countPerPage)
    },
    hasPrev() {
      return this.params.currentPage > 1
    },
    hasNext() {
      return this.params.currentPage != this.getTotalPage()
    },
    getPages() {
      let pages = []
      for (var page = 1; page <= this.getTotalPage(); page++) {
        if (page <= this.params.pageSegment * MAX_PAGES_PER_SEGMENT) {
          continue
        }
        pages.push(page)
        if (pages.length == MAX_PAGES_PER_SEGMENT) {
          break
        }
      }
      return pages
    },
    onChangePage(page) {
      this.onLoadPage(page)
    },
    onClickSegmentMove(delta) {
      if (delta > 0 && this.hasNext()) {
        if (this.params.currentPage % MAX_PAGES_PER_SEGMENT == 0) {
          // eslint-disable-next-line vue/no-mutating-props
          this.params.pageSegment = this.params.pageSegment + 1
          this.onChangePage(this.params.pageSegment * MAX_PAGES_PER_SEGMENT + 1)
        } else {
          this.onChangePage(
            Math.min(this.getTotalPage(), this.params.pageSegment * MAX_PAGES_PER_SEGMENT + MAX_PAGES_PER_SEGMENT)
          )
        }
      } else if (delta < 0 && this.hasPrev()) {
        if (this.params.currentPage % MAX_PAGES_PER_SEGMENT == 1) {
          // eslint-disable-next-line vue/no-mutating-props
          this.params.pageSegment = this.params.pageSegment - 1
          // eslint-disable-next-line vue/no-mutating-props
          this.onChangePage(this.params.pageSegment * MAX_PAGES_PER_SEGMENT + MAX_PAGES_PER_SEGMENT)
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.onChangePage(Math.max(1, this.params.pageSegment * MAX_PAGES_PER_SEGMENT + 1))
        }
      } else {
        return
      }
    },
  },
  components: {},
}
</script>
