<!-- eslint-disable vue/no-mutating-props -->
<template>
  <CCard class="mb-1">
    <CToaster placement="top-end">
      <CToast v-for="(toast, index) in toasts" :key="index">
        <CToastHeader closeButton>
          <span class="me-auto fw-bold">{{ toast.title }}</span>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>
      </CToast>
    </CToaster>
    <CCardHeader class="p-0 m-0 px-1">
      <div class="d-flex">
        <CButton
          class="flex-grow-1 text-left shadow-none p-0 px-1 my-1 ml-1 btn-sm"
          :color="toggleSearchCondition ? 'success' : null"
          @click="
            (event) => {
              if (
                showOrderCondition ||
                showSkuCondition ||
                showMappingCondition ||
                showOosCondition ||
                showSeeAllCondition ||
                showUserLogCondition ||
                showPoCondition ||
                showSuppliersCondition ||
                showPOGroupStatusCondition ||
                showPOStatusCondition
              ) {
                toggleSearchCondition = !toggleSearchCondition
              } else {
                toasts.push({
                  title: '조건 검색을 아직 지원하지 않습니다.',
                  content: null,
                })
              }
            }
          "
          ><CIcon :icon="cilFilter" />검색조건</CButton
        >
        <div>
          <CFormLabel for="formCountPerPage" class="p-0 px-1 my-1 ml-1 btn-sm">페이지 당:</CFormLabel>
          <CDropdown id="formCountPerPage">
            <CDropdownToggle color="primary" variant="outline" size="sm" class="p-0 px-3 my-1 ml-1">{{
              params.countPerPage == 1000000 ? '모두' : params.countPerPage
            }}</CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem
                v-for="(item, idx) in COUNTS_PER_PAGE_LIST"
                :active="params.countPerPage == item.count"
                :key="idx"
                href="#"
                @click="() => (params.countPerPage = item.count)"
                >{{ item.count == 1000000 ? '모두' : item.count }}</CDropdownItem
              >
            </CDropdownMenu>
          </CDropdown>
          <CButton class="p-0 px-3 my-1 ml-1 btn-sm" color="primary" @click="(event) => onClickLoad(event)"
            >검색</CButton
          >
        </div>
        <CAlert v-if="error" color="primary" dismissible>{{ error }}</CAlert>
      </div>
    </CCardHeader>
    <CCollapse :visible="toggleSearchCondition">
      <CCardBody class="p-1">
        <CContainer fluid>
          <CRow class="border-bottom border-light pb-0 mt-0" v-if="showSkuCondition">
            <CInputGroup class="input-prepend" size="sm">
              <CInputGroupText size="sm" class="p-0 px-2 m-1">브랜드</CInputGroupText>
              <CFormCheck
                v-for="(brand, index) in BRAND_LIST"
                :key="index"
                :id="brand.id"
                :label="brand.name"
                :button="cbAttributes"
                :checked="params.brands[index]"
                :disabled="index == 0 && params.brands.slice(1).every((e) => e === false)"
                @change="onBrandsChangeChecked(index)" />
            </CInputGroup>
          </CRow>
          <CRow class="border-bottom border-light pb-0 mt-0 justify-content-start">
            <CCol :md="4" v-if="showSkuCondition">
              <CInputGroup class="input-prepend" size="sm">
                <CInputGroupText size="sm" class="p-0 px-2 m-1">이름</CInputGroupText>
                <CFormInput
                  type="text"
                  class="p-0 px-1 my-1 ml-1 btn-sm"
                  v-model="params.searchKey"
                  v-on:keypress.enter="onKeyEnter()" />
              </CInputGroup>
            </CCol>
            <CCol :md="4" v-if="showPoCondition">
              <CInputGroup class="input-prepend" size="sm">
                <CInputGroupText size="sm" class="p-0 px-2 m-1">발주번호</CInputGroupText>
                <CFormInput
                  type="text"
                  class="p-0 px-1 my-1 ml-1 btn-sm"
                  v-model="params.poNo"
                  v-on:keypress.enter="onKeyEnter()" />
              </CInputGroup>
            </CCol>
            <CCol :md="4" v-if="showOrderCondition">
              <CInputGroup class="input-prepend" size="sm">
                <CInputGroupText size="sm" class="p-0 px-2 m-1">발주옵션</CInputGroupText>
                <CFormCheck
                  id="formCheck1"
                  label="구매 필요"
                  :button="cbAttributes"
                  v-model="params.enableNeedToPurchase" />
                <CFormCheck
                  id="formCheck2"
                  label="완제품 구매"
                  :button="cbAttributes"
                  v-model="params.enableSimplePurchase" />
              </CInputGroup>
            </CCol>
            <CCol :md="4" v-if="showMappingCondition">
              <CInputGroup class="input-prepend" size="sm">
                <CInputGroupText size="sm" class="p-0 px-2 m-1">맵핑옵션</CInputGroupText>
                <CFormCheck id="formCheck3" label="세트상품" :button="cbAttributes" v-model="params.enableCollection" />
                <CFormCheck id="formCheck4" label="에러상품" :button="cbAttributes" v-model="params.enableProblem" />
              </CInputGroup>
            </CCol>
          </CRow>
          <CRow
            v-if="
              showOosCondition ||
              showSeeAllCondition ||
              showSuppliersCondition ||
              showPOGroupStatusCondition ||
              showPOStatusCondition
            "
            class="border-bottom border-light pb-0 mt-0 justify-content-start">
            <CCol :md="6" v-if="showOosCondition">
              <CInputGroup class="input-prepend" size="sm">
                <CInputGroupText size="sm" class="p-0 px-2 m-1">OOS상태</CInputGroupText>
                <CFormCheck
                  v-for="(status, index) in OOS_STATUS_LIST"
                  :key="index"
                  :id="status.id"
                  :label="status.name"
                  :button="cbAttributes"
                  :checked="params.oosSts[index]"
                  :disabled="index == 0 && params.oosSts.slice(1).every((e) => e === false)"
                  @change="onOOSStatusChangeChecked(index)" />
              </CInputGroup>
            </CCol>
            <CCol :md="3" v-if="showSuppliersCondition">
              <CSpinner v-if="suppliersLoading" class="ml-1" component="span" size="sm" aria-hidden="true" />
              <div v-if="params.suppliers.length > 0">
                <CMultiSelect
                  class="p-0 px-1 my-0 ml-1"
                  placeholder="공급처 선택"
                  selection-type-counter-text="개 공급처 선택됨"
                  size="sm"
                  visible-items="10"
                  :selection-type="'counter'"
                  :options="
                    params.suppliers.map((s) => Object.assign({}, { value: s.id, text: s.name, selected: s.selected }))
                  "
                  @change="(values) => onSuppliersChanged(values)" />
              </div>
            </CCol>
            <CCol :md="6" v-if="showPOGroupStatusCondition">
              <CInputGroup class="input-prepend" size="sm">
                <CInputGroupText size="sm" class="p-0 px-2 m-1">발주그룹 상태</CInputGroupText>
                <CFormCheck
                  v-for="(status, index) in PO_GROUP_STATUS_LIST"
                  :key="index"
                  :id="status.id"
                  :label="status.name"
                  :button="cbAttributes"
                  :checked="params.poGrpSts[index]"
                  :disabled="index == 0 && params.poGrpSts.slice(1).every((e) => e === false)"
                  @change="onPOGroupStatusChangeChecked(index)" />
              </CInputGroup>
            </CCol>
            <CCol :md="4" v-if="showPOStatusCondition">
              <CInputGroup class="input-prepend" size="sm">
                <CInputGroupText size="sm" class="p-0 px-2 m-1">발주 상태</CInputGroupText>
                <CFormCheck
                  v-for="(status, index) in PO_STATUS_LIST"
                  :key="index"
                  :id="status.id"
                  :label="status.name"
                  :button="cbAttributes"
                  :checked="params.poSts[index]"
                  :disabled="index == 0 && params.poSts.slice(1).every((e) => e === false)"
                  @change="onPOStatusChangeChecked(index)" />
              </CInputGroup>
            </CCol>
            <CCol :md="3" v-if="showSeeAllCondition">
              <CInputGroup class="input-prepend" size="sm">
                <CInputGroupText size="sm" class="p-0 px-2 m-1">기타</CInputGroupText>
                <CFormCheck
                  id="formCheck5"
                  label="모두보기"
                  :button="cbAttributes"
                  v-model="params.enableSeeAllItems"
                  @click="
                    () => {
                      if (!params.enableSeeAllItems) {
                        toasts.push({
                          title: '숨김 항목 포함',
                          content: null,
                        })
                      }
                    }
                  " />
              </CInputGroup>
            </CCol>
          </CRow>
          <CRow v-if="showUserLogCondition" class="border-bottom border-light pb-0 mt-0 justify-content-start">
            <CCol :md="12">
              <CInputGroup class="input-prepend" size="sm">
                <CInputGroupText size="sm" class="p-0 px-2 m-1">카테고리</CInputGroupText>
                <CFormCheck
                  v-for="(status, index) in USERLOG_CATEGORY_LIST"
                  :key="index"
                  :id="status.id"
                  :label="status.name"
                  :button="cbAttributes"
                  :checked="params.logCates[index]"
                  :disabled="index == 0 && params.logCates.slice(1).every((e) => e === false)"
                  @change="onLogCategoryChangeChecked(index)" />
              </CInputGroup>
            </CCol>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCollapse>
  </CCard>
</template>

<script>
import gql from 'graphql-tag'
import {
  BRAND_LIST,
  OOS_STATUS_LIST,
  USERLOG_CATEGORY_LIST,
  COUNTS_PER_PAGE_LIST,
  PO_GROUP_STATUS_LIST,
  PO_STATUS_LIST,
} from '@/constants.js'
import { cilFilter } from '@coreui/icons'
import { CFormLabel, CRow } from '@coreui/vue'
import { useLazyQuery } from '@vue/apollo-composable'
import { getGraphQLOptionsWithAuthToken } from '@/auth'
import { clone } from '@/utils'
import { CMultiSelect } from '@coreui/vue-pro'

export default {
  name: 'SearchConditionsPane',
  props: {
    params: {
      type: Object,
      required: true,
    },
    onSearch: {
      type: Function,
      required: true,
    },
    toggleOn: {
      type: Boolean,
    },
    showOrderCondition: Boolean,
    showSkuCondition: Boolean,
    showMappingCondition: Boolean,
    showOosCondition: Boolean,
    showSeeAllCondition: Boolean,
    showUserLogCondition: Boolean,
    showPoCondition: Boolean,
    showSuppliersCondition: Boolean,
    showPOGroupStatusCondition: Boolean,
    showPOStatusCondition: Boolean,
  },
  components: { CFormLabel, CRow, CMultiSelect },
  setup() {
    const {
      load: loadSuppliers,
      onResult: onSuppliersResult,
      onError: onSuppliersError,
    } = useLazyQuery(gql`
      query Query {
        allScmSuppliers(status: null) {
          edges {
            node {
              id
              code
              name
            }
          }
        }
      }
    `)
    return {
      loadSuppliers,
      onSuppliersResult,
      onSuppliersError,
      BRAND_LIST,
      OOS_STATUS_LIST,
      USERLOG_CATEGORY_LIST,
      PO_GROUP_STATUS_LIST,
      PO_STATUS_LIST,
      cilFilter,
      cbAttributes: {
        color: 'success',
        variant: 'outline',
        shape: 'rounded-2', // rounded-pill
        size: 'sm',
        class: 'p-0 px-1 my-1 ml-1 btn-sm',
        style: 'min-width: 70px',
      },
      COUNTS_PER_PAGE_LIST,
    }
  },
  data() {
    return {
      toggleSearchCondition: this.toggleOn,
      toasts: [],
      suppliersLoading: false,
      error: null,
    }
  },
  created() {
    this.onSuppliersResult((result) => {
      this.suppliersLoading = result.loading
      // eslint-disable-next-line vue/no-mutating-props
      this.params.suppliers = []
      result.data?.allScmSuppliers.edges.forEach((element) => {
        let item = clone(element.node)
        item.selected = true
        // eslint-disable-next-line vue/no-mutating-props
        this.params.suppliers.push(item)
      })
    })
    this.onSuppliersError((error) => {
      this.error = error
      this.suppliersLoading = false
    })
  },
  mounted() {
    if (this.showSuppliersCondition) {
      this.suppliersLoading = true
      this.loadSuppliers(
        null,
        {},
        Object.assign({}, getGraphQLOptionsWithAuthToken(), {
          fetchPolicy: 'no-cache',
        })
      )
    }
  },
  unmounted() {},
  methods: {
    onSuppliersChanged(values) {
      var selecdtedIds = values.filter((v) => v.selected).map((v) => v.value)
      for (var i = 0; i < this.params.suppliers.length; i++) {
        // eslint-disable-next-line vue/no-mutating-props
        this.params.suppliers[i].selected = selecdtedIds.includes(this.params.suppliers[i].id)
      }
    },
    onKeyEnter() {
      this.onSearch()
    },
    onClickLoad(event) {
      event.stopPropagation()
      this.onSearch()
    },
    _onCommandChangeChecked(objs, index) {
      objs[index] = !objs[index]
      if (index == 0 && objs[index]) {
        for (var i = 1; i < objs.length; i++) {
          objs[i] = false
        }
      } else if (index > 0 && objs[index]) {
        objs[0] = false
      }
      if (objs.every((v) => v == false)) {
        objs[0] = true
      }
    },
    onLogCategoryChangeChecked(index) {
      this._onCommandChangeChecked(this.params.logCates, index)
    },
    onPOGroupStatusChangeChecked(index) {
      this._onCommandChangeChecked(this.params.poGrpSts, index)
    },
    onPOStatusChangeChecked(index) {
      this._onCommandChangeChecked(this.params.poSts, index)
    },
    onOOSStatusChangeChecked(index) {
      this._onCommandChangeChecked(this.params.oosSts, index)
    },
    onBrandsChangeChecked(index) {
      this._onCommandChangeChecked(this.params.brands, index)
    },
  },
}
</script>
