export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavTitle',
    name: '도구모음',
  },
  {
    component: 'CNavItem',
    name: '브랜드 긴급 알람',
    to: '/report/emergency/all',
    icon: 'cil-bell',
    badge: {
      color: 'primary',
      text: 'NEW',
    },
  },
  // {
  //   component: 'CNavItem',
  //   name: '판매자 등급',
  //   to: '/dashboard/brand-grade/fn9w240b0esu89o7froz0pgmnw2004p92w',
  //   icon: 'cil-layers',
  //   badge: {
  //     color: 'primary',
  //     text: 'NEW',
  //   },
  // },
  {
    component: 'CNavItem',
    name: 'SAP 판매오더 누락 목록',
    to: '/report/missing-sales-orders/',
    icon: 'cil-drop',
  },
  {
    component: 'CNavTitle',
    name: 'SCM',
  },
  {
    component: 'CNavItem',
    name: '창고 - 재고',
    to: '/scm/warehouse-stocks/',
    icon: 'cil-basket',
  },
  {
    component: 'CNavGroup',
    name: '발주',
    icon: 'cilGlobeAlt',
    visible: true,
    items: [
      {
        component: 'CNavItem',
        name: '발주 생성',
        to: '/scm-po/purchase-order-builder/',
        icon: 'cilFile',
      },
      {
        component: 'CNavItem',
        name: '발주 목록',
        to: '/scm-po/purchase-orders/',
        icon: 'cilList',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: '마스터 공급처',
    to: '/scm/master-suppliers/',
    icon: 'cilFactory',
  },
  {
    component: 'CNavItem',
    name: '마스터 아이템',
    to: '/scm/master-materials/operable',
    icon: 'cil-task',
  },
  {
    component: 'CNavItem',
    name: '주문 맵핑',
    to: '/scm/order-mapping/',
    icon: 'cil-puzzle',
  },
  {
    component: 'CNavTitle',
    name: 'Extras',
  },
  {
    component: 'CNavGroup',
    name: '템플릿 테스트',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: '템플릿 테스트',
        to: '/po/template/79e16129-9100-48f7-8202-4127a61b3c52',
      },
      {
        component: 'CNavItem',
        name: '템플릿 테스트2',
        to: '/po/template2/79e16129-9100-48f7-8202-4127a61b3c52',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Deprecated',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'PlusCL 재고',
        to: '/scm/pluscl-stocks-deprecated/',
        icon: 'cil-basket',
      },
      {
        component: 'CNavItem',
        name: 'PlusCL 재고 이벤트',
        to: '/report/pluscl-stock-events-deprecated/',
        icon: 'cil-basket',
      },
      {
        component: 'CNavItem',
        name: 'NSync 이력 조회',
        to: '/report/nsync-history-deprecated/',
        icon: 'cilCoffee',
      },
      {
        component: 'CNavItem',
        name: '마스터 아이템(보류)',
        to: '/scm/master-materials/inoperable',
        icon: 'cilBan',
      },
    ],
  },
]
