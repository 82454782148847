<template>
  <CHeader position="sticky" class="m-0 mb-1">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav>
        <CNavItem>
          <CNavLink size="sm" @click="() => onShowUserLogModal()">
            <CIcon class="mx-1" icon="cilLightbulb" size="sm" />
            <span>변경이력</span>
          </CNavLink>
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <!-- <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer> -->
  </CHeader>
  <CModal alignment="center" size="xl" :visible="showUserLogModal" @close="() => (showUserLogModal = false)">
    <CModalHeader>
      <CModalTitle>변경 이력</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <UserLogsPane />
    </CModalBody>
  </CModal>
</template>

<script>
// import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import UserLogsPane from '@/components/common/UserLogsPane.vue'
import { authToken } from '@/auth'

export default {
  name: 'AppHeader',
  components: {
    // AppBreadcrumb,
    AppHeaderDropdownAccnt,
    UserLogsPane,
  },
  setup() {
    const logo = require('@/assets/logo.png')
    return {
      logo,
    }
  },
  data() {
    return {
      showUserLogModal: false,
    }
  },
  methods: {
    onShowUserLogModal() {
      {
        if (authToken()) {
          this.showUserLogModal = true
        }
      }
    },
  },
}
</script>
