export const BRAND_LIST = [
  { name: '전체', id: 'all' },
  { name: '프랑떼', id: 'frante', mnemonics: 'FRANTE|FRT' },
  { name: '라온', id: 'raon', mnemonics: 'RAON|RAO' },
  { name: '바렌', id: 'baren', mnemonics: 'BAREN|BRN' },
  { name: '옥희독희', id: 'ohdh', mnemonics: 'OHDH|ODH' },
  { name: '나인포인트', id: 'npnt', mnemonics: 'NPNT|NPT' },
  { name: '몬스터', id: 'mstr', mnemonics: 'MSTR|MST' },
  { name: '멍뭉스', id: 'mmgs', mnemonics: 'MMGS|MMS' },
  { name: '네오핏', id: 'neofit', mnemonics: 'NEOFIT|NEO' },
  { name: '자취생활연구소', id: 'jachi', mnemonics: 'JACHI|JAL' },
  { name: '파비츠', id: 'favits', mnemonics: 'FAVITS|FAV' },
  { name: '컴포럽', id: 'cplv', mnemonics: 'CPLV|CPL' },
  { name: '브릭글로', id: 'brgl', mnemonics: 'BRGL|BRG' },
  { name: '카이토', id: 'KTO', mnemonics: 'KTO' },
  { name: '메가', id: 'MGA', mnemonics: 'MGA' },
]

export function guessBrand(s) {
  for (const b of BRAND_LIST) {
    if (b.name.includes(s)) {
      return b
    }
    if (b.mnemonics) {
      for (const mnemonic of b.mnemonics.split('|')) {
        if (s.toUpperCase().includes(mnemonic)) {
          return b
        }
      }
    }
  }
  return null
}

export const OOS_STATUS_LIST = [
  { name: '전체', id: 'oos-all' },
  { name: 'OOS중', id: 'oos-0' },
  { name: '3일내 OOS', id: 'oos-3' },
  { name: '7일내 OOS', id: 'oos-7' },
  { name: '판매량 없음', id: 'oos-infinite' },
]

export const USERLOG_CATEGORY_LIST = [
  { name: '전체', id: 'ls-all' },
  { name: '세트상품 가격맵핑', id: '1' },
  { name: '판매상태 변경', id: '2' },
  { name: '재고 이벤트 변경', id: '3' },
]

export const PO_GROUP_STATUS_LIST = [
  { name: '전체', id: 'els-all' },
  { name: 'PENDING', id: '1' },
  { name: 'SENT', id: '2' },
  { name: 'IN PROGRESS', id: '3' },
  { name: 'DONE', id: '4' },
]

export const PO_STATUS_LIST = [
  { name: '전체', id: 'sls-all' },
  { name: '완료됨', id: 's0' },
  { name: '진행중', id: 's1' }, // 0: finish, 1: remaining
]

export const INITIAL_BRAND_FILTER = { name: '전체', id: 'all' }

export const COUNTS_PER_PAGE_LIST = [
  { count: 5 },
  { count: 25 },
  { count: 50 },
  { count: 100 },
  { count: 300 },
  { count: 500 },
  { count: 1000000 },
]

export const COMBINED_SALES_STATUS = {
  MasterIgnored: {
    id: 1,
    derived: 1,
    color: 'secondary',
    name: '제외(무시)',
    description: '부속품 등 창고 재고에는 보이지만, OOS관리는 하지 않는 경우입니다.',
    configuable: true,
  },
  MasterInProgress: {
    id: 2,
    derived: 2,
    color: 'secondary',
    name: '제외(작업중)',
    description: '이관작업 등, 아직 정리가 끝나지 않은 제품입니다.',
    configuable: true,
  },
  MasterExternalOp: {
    id: 3,
    derived: 3,
    color: 'secondary',
    name: '제외(외부관리)',
    description: '재고를 외부에서 관리하는 제품입니.(eg.대웅피앤피)',
    configuable: true,
  },
  MasterDiscontinued: {
    id: 4,
    color: 'secondary',
    name: '제외(단종)',
    description: '영구 단종 제품입니다.',
    configuable: true,
  },
  SalesUnknown: {
    id: 100,
    derived: 100,
    color: 'secondary',
    name: '알수없음',
    description: '판매중인지 아닌지 확인 필요.',
    configuable: true,
  },
  SalesOnSale: {
    id: 101,
    derived: 101,
    color: 'primary',
    name: '판매중',
    description: '정상 판매중입니다.',
    configuable: true,
  },
  SalesOnSaleWithoutStock: {
    id: 102,
    derived: 101,
    color: 'danger',
    name: '판매중(재고부족)',
    description: '판매중이지만 재고가 없는 상태입니다. 더보기를 확인하세요.',
    instructions: `(1) 모든 마켓플레이스에서 해당 SKU 판매 중단 조치
(2) 본 UI 상에서 [판매 상태]를 ‘일시 판매 중단' 혹은 ‘장기 판매 중단’ 혹은 ‘영구 단종’으로 변경
> 별다른 의사결정이 없는 이상 모두 ‘일시 판매 중단’으로 변경해야 함. 
> ‘장기 판매 중단’은 시즈널리티 등 여하의 이유로 장기적으로 판매하지 않을 SKU의 [판매 상태]를 의미하며 Alert 을 받지 않도록 하기 위해 사용.`,
    configuable: false,
  },
  SalesOffSaleWithStock: {
    id: 103,
    derived: 104,
    color: 'danger',
    name: '일시판중(재고O)',
    description: '재고가 있지만, 일시적으로 판매중단 상태입니다. 더보기를 확인하세요.',
    instructions: `(1) 마켓플레이스 상에서 해당 SKU 판매 재개 조치
(2) 본 UI 상에서 ‘판매 중’으로 변경
> 일시적으로 판매 중단되었지만, 재입고 되어 재고가 다시 보충된 경우입니다.`,
    configuable: false,
  },
  SalesOffSale: {
    id: 104,
    derived: 104,
    color: 'warning',
    name: '일시판중',
    description: '일시적으로 판매중단 상태입니다.',
    configuable: true,
  },
  SalesLongTermOffSaleWithStock: {
    id: 105,
    derived: 106,
    color: 'info',
    name: '장기판중(재고O)',
    description: '재고가 있지만, 장기 판매중단 상태입니다.',
    configuable: false,
  },
  SalesLongTermOffSale: {
    id: 106,
    derived: 106,
    color: 'info',
    name: '장기판중',
    description: '장기 판매중단 상태입니다.',
    configuable: true,
  },
}
