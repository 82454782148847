import App from '@/App.vue'
import { iconsSet as icons } from '@/assets/icons'
import { apolloClientDefault, apolloClientWithAuth } from '@/auth'
import router from '@/router'
import store from '@/store'
import CIcon from '@coreui/icons-vue'
import CoreuiVue from '@coreui/vue'
import { ApolloClients, DefaultApolloClient } from '@vue/apollo-composable'
import { createApp, h, provide } from 'vue'
import JsonExcel from 'vue-json-excel3'
import VueGtag from 'vue-gtag'

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClientDefault)
    provide(ApolloClients, {
      default: apolloClientDefault,
      withAuth: apolloClientWithAuth,
    })
  },
  render: () => h(App),
})

app.use(CoreuiVue)
app.use(store)
app.use(router)
app.use(VueGtag, {
  config: {
    id: 'G-4M183FKCR6', // Google Analytics의 Tracking ID를 넣어준다
  },
})
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('downloadExcel', JsonExcel)
app.mount('#app')
