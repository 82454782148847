<template>
  <div>
    <CCard class="mb-2">
      <CCardHeader>
        <SearchConditionsPane
          class="d-flex flex-column mt-5"
          style="width: 100%"
          :params="searchProps"
          :on-search="() => onSearch()"
          :show-user-log-condition="true" />
      </CCardHeader>
      <CAlert v-if="error" color="primary" dismissible @close="() => router.push({ path: '/' })">{{ error }}</CAlert>
      <CCardBody>
        <div class="century-table-scrollbar">
          <CSmartTable
            v-if="items.length > 0"
            responsive
            tableFilter
            tableFilterLabel="필터"
            columnSorter
            :columns="columns"
            :items="items"
            :tableProps="{
              striped: true,
              hover: true,
            }">
          </CSmartTable>
          <div class="center-box">
            <CSpinner v-if="loading" color="primary" grow />
          </div>
        </div>
      </CCardBody>
      <CCardFooter>
        <PaginationPane class="d-flex" :params="pageProps" :on-load-page="onLoadPage" />
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import { getGraphQLOptionsWithAuthToken } from '@/auth'
import router from '@/router'
import { clone, formattedDatetimeAsKST, getInitialPageProps, getInitialSearchProps } from '@/utils'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import PaginationPane from '@/components/common/PaginationPane.vue'
import { CCardFooter } from '@coreui/vue'
import { CSmartTable } from '@coreui/vue-pro'
import SearchConditionsPane from '@/components/common/SearchConditionsPane.vue'
import { USERLOG_CATEGORY_LIST } from '@/constants'

export default {
  name: 'UserLogsPane',
  setup() {
    const {
      load: loadPage,
      onResult: onResult,
      onError: onError,
    } = useLazyQuery(gql`
      query Query($categories: String, $countPerPage: Int!, $offset: Int!) {
        allUserLogs(categories: $categories, first: $countPerPage, offset: $offset, orderBy: ["-id"]) {
          edges {
            node {
              id
              user {
                id
                username
              }
              category
              message
              createdAt
            }
          }
          totalCount
        }
      }
    `)
    return {
      loadPage,
      onResult,
      onError,
      router,
    }
  },
  data() {
    return {
      columns: [],
      items: [],
      pageProps: getInitialPageProps(),
      searchProps: getInitialSearchProps(),
      loading: false,
      error: null,
      showEditOrderSetPricesModal: false,
      mappingKeyToEdit: null,
    }
  },
  created() {
    function getCategoryName(id) {
      for (var i = 0; i < USERLOG_CATEGORY_LIST.length; i++) {
        if (USERLOG_CATEGORY_LIST[i].id == id) {
          return USERLOG_CATEGORY_LIST[i].name
        }
      }
    }
    this.onResult((result) => {
      this.loading = result.loading
      this.columns = [
        { key: 'ID', _props: { align: 'middle' } },
        { key: '카테고리', label: '카테고리', _props: { align: 'middle' } },
        { key: 'User', label: 'User', _props: { align: 'middle' } },
        { key: '내용', label: '내용', _props: { align: 'middle' } },
        { key: '날짜', label: '날짜', _props: { align: 'middle' } },
      ]
      this.items = []
      result.data?.allUserLogs.edges.forEach((element) => {
        let item = clone(element.node)
        item['ID'] = item.id
        item['카테고리'] = getCategoryName(item.category)
        item['User'] = item.user.username
        item['내용'] = item.message
        item['날짜'] = formattedDatetimeAsKST(item.createdAt)
        item['_cellProps'] = { all: { class: 'small align-middle' } }
        item['_props'] = { color: 'light' }

        this.items.push(item)
      })
      this.pageProps.countPerPage = this.searchProps.countPerPage
      this.pageProps.totalCount = result.data?.allUserLogs.totalCount
    })
    this.onError((error) => {
      this.error = error
      this.loading = false
    })
  },
  mounted() {
    this.onSearch()
  },
  unmounted() {},
  methods: {
    onEditIfError(item) {
      this.showEditOrderSetPricesModal = true
      this.mappingKeyToEdit = item.key
    },
    onSearch() {
      this.loading = false
      this.pageProps.totalCount = 0
      this.pageProps.pageSegment = 0
      this.items = []
      this.onLoadPage(1)
    },
    onLoadPage(page) {
      this.pageProps.currentPage = page
      this.loading = true
      this.error = null

      let categories = new Set()
      for (var i = 1; i < this.searchProps.logCates.length; i++) {
        if (this.searchProps.logCates[i]) {
          categories.add(i)
        }
      }
      const csvCategories = categories.size > 0 ? Array.from(categories).join(',') : null
      this.loadPage(
        null,
        {
          categories: csvCategories,
          countPerPage: this.searchProps.countPerPage,
          offset: (page - 1) * this.searchProps.countPerPage,
        },
        Object.assign({}, getGraphQLOptionsWithAuthToken(), {
          fetchPolicy: 'no-cache',
        })
      )
    },
  },
  components: {
    PaginationPane,
    CCardFooter,
    CSmartTable,
    SearchConditionsPane,
  },
}
</script>
