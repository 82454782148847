import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import NotFound from '@/views/NotFound.vue'

const defaultRoutes = [
  {
    path: '/po/:name/:key',
    name: 'PO',
    component: () => import(/* webpackChunkName: "po" */ '@/views/PurchaseOrderTemplateView.vue'),
  },
  {
    path: '/cafe24/oauth/',
    name: 'Cafe24 OAuth',
    component: () => import(/* webpackChunkName: "po" */ '@/views/Cafe24OAuthView.vue'),
    params: true,
  },
  {
    path: '/dashboard/brand-grade/:key',
    name: 'Brand Grade Dashboard',
    component: () =>
      import(/* webpackChunkName: "brand-grade-dashboard" */ '@/views/BrandGradeDashboardTemplateView.vue'),
  },
  {
    path: '/jachi/:name',
    name: 'JACHI',
    component: () => import(/* webpackChunkName: "jachi" */ '@/views/JachiView.vue'),
  },
  {
    path: '/',
    name: '',
    component: DefaultLayout,
    redirect: '/',
    children: [
      {
        path: '/',
        name: '',
        component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView.vue'),
      },
      {
        path: '/password-reset',
        name: 'Password Reset',
        component: () => import(/* webpackChunkName: "password-reset" */ '@/views/PasswordResetView.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/DashboardView.vue'),
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue'),
      },
      {
        path: '/report/:name/:key?',
        name: 'Report',
        component: () => import(/* webpackChunkName: "report" */ '@/views/ReportView.vue'),
      },
      {
        path: '/scm-po/purchase-order-builder',
        name: 'PO Builder',
        component: () => import(/* webpackChunkName: "scm-po-builder" */ '@/views/PurchaseDraftOrdersView.vue'),
      },
      {
        path: '/scm-po/purchase-orders',
        name: 'PO Manager',
        component: () => import(/* webpackChunkName: "scm-po-manager" */ '@/views/PurchaseOrdersView.vue'),
        params: true,
      },
      {
        path: '/scm/:name/:key?',
        name: 'SCM',
        component: () => import(/* webpackChunkName: "scm" */ '@/views/SCMView.vue'),
        params: true,
      },
    ],
  },
  {
    path: '/404',
    name: 'notFound',
    component: NotFound,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
]

const publicRoutes = [
  {
    path: '/jachi/:name',
    name: 'JACHI',
    component: () => import(/* webpackChunkName: "jachi" */ '@/views/JachiView.vue'),
  },
  {
    path: '/404',
    name: 'notFound',
    component: NotFound,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
]

const host = window.location.host
const parts = host.split('.')
const dynamicRouter = () => {
  if (parts[0] === 'public') {
    return publicRoutes
  }
  return defaultRoutes
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: dynamicRouter(),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
