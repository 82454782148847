import router from '@/router'
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client/core'

export function authToken() {
  let user = JSON.parse(localStorage.getItem('user'))
  if (user && user?.tokenAuth?.token) {
    return user.tokenAuth.token
  } else {
    return null
  }
}

export function authHeader() {
  let token = authToken()
  if (token) {
    return {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    }
  } else {
    return {}
  }
}

// to local testing
// make .env file in webserver/.env contains below line
// VUE_APP_GRAPHGL_URL=http://localhost:8001
const GRAPHENE_URL = process.env.VUE_APP_GRAPHGL_URL || 'https://internal.marslabs.co.kr/graphql'

const httpLink = new HttpLink({
  uri: GRAPHENE_URL,
})

const cache = new InMemoryCache()
export const apolloClientDefault = new ApolloClient({
  cache: cache,
  link: httpLink,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = authToken()
  operation.setContext({
    headers: {
      authorization: token ? `JWT ${token}` : '',
    },
  })
  return forward(operation)
})

export const apolloClientWithAuth = new ApolloClient({
  cache: cache,
  link: authMiddleware.concat(httpLink),
  credentials: 'include',
})

export function getGraphQLOptionsWithAuthToken() {
  return {
    context: {
      headers: {
        authorization: authToken() ? `JWT ${authToken()}` : '',
      },
    },
  }
}

export function handleError(error) {
  if (
    error &&
    (error.message === 'You do not have permission to perform this action' ||
      error.message === 'Signature has expired' ||
      error.message === 'Error decoding signature')
  ) {
    if (window.location.pathname.length > 3) {
      router.push({ path: '/', query: { redirect: window.location.pathname } })
    } else {
      router.push({ path: '/' })
    }
  }
}
