<template>
  <CFooter>
    <div class="small">
      Copyright
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} <a href="https://www.marslabs.co.kr">MARSLABS</a> All rights
        reserved.</span
      >
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
