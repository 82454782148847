import {
  BRAND_LIST,
  guessBrand,
  COUNTS_PER_PAGE_LIST,
  OOS_STATUS_LIST,
  USERLOG_CATEGORY_LIST,
  PO_GROUP_STATUS_LIST,
  PO_STATUS_LIST,
} from '@/constants.js'
import moment from 'moment'

export function clone(a) {
  return JSON.parse(JSON.stringify(a))
}

export function getFieldValue(item, field) {
  if (field.includes('.')) {
    const fields = field.split('.')
    var tmp = item
    for (var i = 0; i < fields.length; i++) {
      tmp = Reflect.get(tmp, fields[i])
    }
    return tmp
  } else {
    return Reflect.get(item, field)
  }
}

export function objectToArray(item, sortedFields) {
  let arr = []
  sortedFields.forEach((f) => {
    arr.push(getFieldValue(item, f))
  })
  return arr
}

export function formattedDatetimeAsKST(dt) {
  return moment(Date.parse(dt)).format('YYYY-MM-DD HH:mm:ss')
}

export function formattedSimpleDatetimeAsKST(dt) {
  const s = new Date(Date.parse(dt)).toLocaleString('ko-KR', {
    timeZone: 'Asia/Seoul',
  })
  return s.slice(6).slice(0, -3)
}

export function formattedDateAsKST(dt) {
  return moment(Date.parse(dt)).format('YYYY-MM-DD')
}

export function brandFromItemCode(itemCode) {
  return guessBrand(itemCode)
}

export function getInitialPageProps() {
  return {
    totalCount: 0,
    pageSegment: 0,
    currentPage: 1,
    countPerPage: null,
  }
}

export function getInitialSearchProps() {
  const conditionBrands = Array(BRAND_LIST.length).fill(false)
  conditionBrands[0] = true
  const conditionOOSs = Array(OOS_STATUS_LIST.length).fill(false)
  conditionOOSs[0] = true
  const conditionPOGrpStats = Array(PO_GROUP_STATUS_LIST.length).fill(false)
  conditionPOGrpStats[0] = true
  const conditionPOStats = Array(PO_STATUS_LIST.length).fill(false)
  conditionPOStats[0] = true
  const conditionUserLogs = Array(USERLOG_CATEGORY_LIST.length).fill(false)
  conditionUserLogs[0] = true
  const searchProps = {
    countPerPage: COUNTS_PER_PAGE_LIST[1].count,
    brands: conditionBrands,
    oosSts: conditionOOSs,
    poGrpSts: conditionPOGrpStats,
    poSts: conditionPOStats,
    logCates: conditionUserLogs,
    searchKey: null,
    poNo: null,
    enableNeedToPurchase: false,
    enableSimplePurchase: false,
    enableCollection: false,
    enableProblem: false,
    enableSeeAllItems: false,
    suppliers: [],
  }
  return Object.assign({}, searchProps)
}

export function makeRegexForBrands(searchProps) {
  var brand_regex = null // 'ml-(ohdh|frante)-.*'
  if (!searchProps.brands[0]) {
    brand_regex = `.*(${BRAND_LIST.filter((e, idx) => searchProps.brands[idx])
      .map((e) => e.mnemonics)
      .join('|')}).*`
  }
  return brand_regex
}

export function makeCSVForBrandNames(searchProps) {
  var brands = null // '프랑떼,옥희독희'
  if (!searchProps.brands[0]) {
    brands = BRAND_LIST.filter((e, idx) => searchProps.brands[idx])
      .map((e) => e.name)
      .join(',')
  }
  return brands
}
