<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="6">
          <div class="clearfix">
            <h1 class="float-start display-3 me-4">404</h1>
            <h4 class="pt-3">페이지를 찾을 수 없습니다.</h4>
            <p class="text-medium-emphasis float-start"><a href="/">루트 페이지</a>로 이동하세요.</p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>
