<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-0">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import router from '@/router'
import { authToken, getGraphQLOptionsWithAuthToken, handleError } from '@/auth'

export default {
  name: 'DefaultLayout',
  setup() {
    const { result, loading, error } = useQuery(
      gql`
        query Me {
          me {
            username
          }
        }
      `,
      null,
      getGraphQLOptionsWithAuthToken()
    )
    const { mutate: refreshToken } = useMutation(
      gql`
        mutation RefreshToken($token: String!) {
          refreshToken(token: $token) {
            token
            payload
            refreshExpiresIn
          }
        }
      `,
      getGraphQLOptionsWithAuthToken()
    )
    return {
      result,
      loading,
      error,
      refreshToken,
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    result(newValue, oldValue) {
      if (newValue?.me?.username) {
        console.log(`username=${newValue?.me?.username}`)
      } else {
        router.push({ path: '/' })
      }
    },
    error(newValue) {
      handleError(newValue)
    },
  },
  mounted() {
    let token = authToken()
    if (token) {
      let refreshNeeded = Number(localStorage.getItem('refreshNeeded') || '0')
      let now = new Date().getTime()
      if (refreshNeeded < now) {
        this.refreshToken({ token: token })
          .then((response) => {
            if (response.data.refreshToken.token) {
              let user = JSON.parse(localStorage.getItem('user'))
              user.tokenAuth.token = response.data.refreshToken.token
              user.tokenAuth.refreshExpiresIn = response.data.refreshToken.refreshExpiresIn
              localStorage.setItem('user', JSON.stringify(user))
              localStorage.setItem('refreshNeeded', `${new Date().getTime() + 5 * 60 * 1000}`)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  },
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
}
</script>
